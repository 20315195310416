<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Timesheets">
			<!-- <b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(3, 'Validate Timesheets') }}</h1>
			</b-col> -->
			<b-col cols="12" xl="12">
				<div class="empty-state" v-if="MyTSObj.length === 0">
					<p>{{ this.FormMSG(10, 'No timesheet to validate.') }}</p>
				</div>
				<div v-if="$screen.width < 992">
					<b-overlay :show.sync="loadingMobile" opacity="0.75" rounded="lg">
						<MobileViewsValidateTs
							:time-sheets="MyTSObj"
							:fields="tsFields"
							@validate-item="validateItem"
							@mobile-view-validate-timesheet:clicked="rowClicked"
							@mobile-view-validate-timesheet:validated="validateMobileItem"
						/>
					</b-overlay>
				</div>
				<div class="container-layout details-document-package p-0" v-else>
					<b-card no-body class="card-border-blue-light" v-for="oneItem in MyTSObj" :key="oneItem.depName">
						<b-row class="back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
							<b-col cols="6">
								<h2 class="d-flex align-items-center">
									<div>{{ oneItem.depName }}</div>
									<b-badge pill class="ml-2">{{ oneItem.numTsForDep }}</b-badge>
								</h2>
							</b-col>
							<b-col cols="6">
								<div class="card-header-actions">
									<b-link class="card-header-action btn-minimize text-white" v-b-toggle="oneItem.depName">
										<i class="icon-arrow-down"></i>
									</b-link>
								</div>
							</b-col>
						</b-row>
						<b-collapse visible :id="oneItem.depName">
							<b-card-body>
								<div v-for="oneUser in oneItem.users" :key="oneUser.fullName" class="pb-3">
									<h5 class="d-flex align-items-center">
										<div>{{ oneUser.fullName }} - {{ oneUser.functionName }}</div>
										<b-badge pill class="ml-2">{{ oneUser.numTsForUser }}</b-badge>
									</h5>
									<b-table
										:hover="hover"
										responsive="sm"
										:items="oneUser.ts"
										text-variant="white"
										:fields="tsFields"
										:current-page="currentPage"
										sticky-header="700px"
										:per-page="perPage"
										@row-clicked="rowClicked"
										:head-variant="hv"
										bordered
										small
									>
										<template v-slot:cell(validatedStatus)="data">
											<div class="wrap-status">
												<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
													{{ data.item.validatedStatus }}
												</div>
											</div>
										</template>
										<template v-slot:cell(encodedBy)="data">
											<div class="d-flex flex-row justify-content-center align-items-center">
												{{ encodedBy(data.item) }}
											</div>
										</template>
										<template v-slot:cell(val)="data">
											<b-button size="sm" class="btn bg-transparent border-0 ml-1" @click="validateItem(data.item.id)">
												<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :color="ICONS.CHECK_SQUARE.color" :size="20" />
											</b-button>
										</template>
									</b-table>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-col>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(11, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
				modal-class="mui-animation"
				:fade="false"
			>
				{{ this.FormMSG(12, 'The timesheet has been validated!') }}
			</b-modal>
		</b-row>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import { rendCurrency, rendKgCo2 } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import MobileViewsValidateTs from '@/components/MobileViews/ValidateTs/Main';
import { mapActions } from 'vuex';
import { classStatus } from '@/shared/global-status';
import moment from 'moment';
import globalMixin from '@/mixins/global.mixin';

const query_timesheets = gql`
	query {
		GetTimesheetsToValidate {
			id
			strDate
			endDate
			week
			totMin
			validated
			comment
			salary
			totMinOvertime
			totMinNight
			totMinTooEarly
			kgCoTwo
			totMinOvertime1
			totMinOvertime2
			transportTimePaid
			hasNotSubmittedDay
			weeklyTotalOvertime
			weeklyTotalOvertimeStr
			weeklyTotMinOvt
			weeklyTotMinOvt1
			weeklyTotMinOvt2
			weeklySalaryOvt
			weeklySalaryOvt1
			weeklySalaryOvt2
			weeklyCarAllowance
			weeklyComputerAllowance
			weeklyProductionFeeAllowance
			weeklyPhoneAllowance
			weeklyBoxKitAllowance
			dailyTotAllowances
			weeklyTotAllowances
			weeklyTotPerDiem
			hours
			minutes
			hoursOvertime
			minutesOvertime
			hoursOvertime1
			hoursOvertime2
			minutesOvertime1
			minutesOvertime2
			hoursTransportTimePaid
			minutesTransportTimePaid
			hoursNight
			minutesNight
			hoursTooEarly
			minutesTooEarly
			department
			departmentName
			function
			functionName
			weeklyTotMinOvtStr
			weeklyTotMinOvt1Str
			weeklyTotMinOvt2Str
			weeklyLunchPerDiem
			weeklyHotelPerDiem
			weeklyDinnerPerDiem
			weeklyAbroadPerDiem
			user {
				name
				firstName
				email
			}
			encoderId
			encoder {
				id
				name
				firstName
				fullName
			}
		}
	}
`;

const mutation_validateItem = gql`
	mutation ($TimesheetId: ID!) {
		ValidateTimesheet(TimesheetId: $TimesheetId) {
			id
		}
	}
`;

export default {
	name: 'ValidateTimesheets',
	components: { MobileViewsValidateTs },
	mixins: [languageMessages, globalMixin],
	props: {
		caption1: {
			type: String,
			default: 'Your timesheets'
		},
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			tableHeaderStyle: {
				color: 'red',
				fontSize: '13px'
			},
			hv: 'dark',
			Validated: 0,
			erreur: {},
			Timesheets: [],
			MyTSObj: [
				{
					depName: '',
					numTsForDep: 0,
					users: [
						{
							fullName: '',
							numTsForUser: 0,
							ts: []
						}
					]
				}
			],
			MyObj: {
				depName: '',
				numTsForDep: 0,
				users: [
					{
						fullName: '',
						numTsForUser: 0,
						ts: []
					}
				]
			},
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			curTs: {
				id: 0,
				strDate: '',
				endDate: '',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			loadingMobile: false
		};
	},
	async created() {
		if (this.curTs.strDate.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.reloadData();
	},
	computed: {
		fliedsBase() {
			let fields = [];
			let baseFields = [
				{
					thStyle: 'tableHeaderStyle',
					key: 'strDate',
					label: this.FormMSG(21, 'Start'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'endDate',
					label: this.FormMSG(22, 'End'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'hhmm',
					label: this.FormMSG(23, 'Hours'),
					sortable: false,
					class: 'text-center'
				}
			];

			fields = fields.concat(baseFields);

			const weeklyOvertime = {
				key: 'weeklyTotalOvertimeStr',
				label: this.FormMSG(29, 'Weekly Overtime'),
				sortable: false,
				class: 'text-center'
			};

			if (this.showWeeklyOvt || this.showWeeklyOvt1 || this.showWeeklyOvt2) {
				fields.push(weeklyOvertime);
			}

			const dailyOvertime = {
				key: 'hhmmOvtTot',
				label: this.FormMSG(30, 'Daily Overtime'),
				sortable: false,
				class: 'text-center'
			};

			if (this.showOvt || this.showOvt1 || this.showOvt2) {
				fields.push(dailyOvertime);
			}

			const transport = {
				key: 'hhmmTransport',
				label: this.FormMSG(242, 'Transport'),
				sortable: false,
				class: 'text-center'
			};

			if (this.showTransport) {
				fields.push(transport);
			}

			const night = {
				key: 'hhmmNight',
				label: this.FormMSG(25, 'Night'),
				sortable: false,
				class: 'text-center'
			};

			if (this.showNightHours) {
				fields.push(night);
			}

			const anticipated = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(26, 'Antic.'),
				sortable: false,
				class: 'text-center'
			};

			if (this.showTooEarlyHours) {
				fields.push(anticipated);
			}

			const perDiem = {
				key: 'weeklyTotPerDiem',
				label: this.FormMSG(31, 'Per Diem'),
				formatter: (value) => {
					return rendCurrency(value);
				},
				sortable: false,
				class: 'text-center'
			};

			if (this.showLunchPerDiem || this.showHotelPerDiem || this.showDinnerPerDiem || this.showAbroadPerDiem) {
				fields.push(perDiem);
			}

			const baseLastFields = [
				{
					key: 'weeklyTotAllowances',
					label: this.FormMSG(32, 'Allowances'),
					formatter: (v, z, root) => {
						return rendCurrency(root.dailyTotAllowances + root.weeklyTotAllowances);
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					class: 'text-right',
					sortable: true
				}
			];
			const salary = {
				key: 'salary',
				label: this.FormMSG(25000, 'Salary'),
				formatter: (value) => {
					return rendCurrency(value);
				},
				class: 'text-right',
				sortable: false
			};

			if (!store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget()) {
				baseLastFields.push(salary);
			}

			fields = fields.concat(baseLastFields);

			return fields;
		},
		encodedByField() {
			if (this.useEntryForCrewTimesheet) {
				return {
					key: 'encodedBy',
					label: this.FormMSG(300, 'Encoded By'),
					sortable: true,
					class: 'text-center'
				};
			}
		},
		useEntryForCrewTimesheet() {
			return store.state.myProject.useEntryForCrewTimesheet;
		},
		tsFields() {
			let flds = [];
			const statusFld = {
				key: 'validatedStatus',
				label: this.FormMSG(20, 'Status'),
				sortable: true,
				class: 'text-center'
			};
			flds.push(statusFld);

			const fldsBase = this.fliedsBase;
			flds = flds.concat(fldsBase);

			const validate = {
				key: 'val',
				label: this.FormMSG(28, 'Validate'),
				formatter: (v) => v,
				class: 'text-center',
				isSlot: true
			};
			flds.push(validate);
			let arrays = [...flds.slice(0, 3), this.encodedByField, ...flds.slice(3)].filter((item) => item !== undefined);
			return arrays;
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '14px'
			};
			if (this.curTs.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curTs.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curTs.validated == 8) {
				myObj.color = 'green';
			} else if (this.curTs.validated == 4 || this.curTs.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		},
		showOvt() {
			return store.state.myProject.useOvertime;
		},
		showOvt1() {
			return store.state.myProject.useOvt1;
		},
		showOvt2() {
			return store.state.myProject.useOvt2;
		},
		showWeeklyOvt() {
			return store.state.myProject.useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.state.myProject.useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.state.myProject.useWeeklyOvt2;
		},
		showTransport() {
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		showTooEarlyHours() {
			return store.state.myProject.useTooEarlyHours;
		},
		showNightHours() {
			return store.state.myProject.useNightHours;
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		encodedBy(data) {
			return `${data.encoder.name} ${data.encoder.firstName}`;
		},
		async validateItem(id) {
			const action = async () => {
				const TimesheetId = parseInt(id, 10);
				await this.$apollo
					.mutate({
						mutation: mutation_validateItem,
						variables: {
							TimesheetId
						}
					})
					.then(async (result) => {
						await this.getNotifications();
						await this.reloadData();
						this.successModal = true;
					})
					.catch((error) => {
						console.log(error);
						this.erreur = error;
					});
			};
			await this.confirmModal(action, this.FormMSG(6000, 'Do you really want to validate this item?'));
		},
		async validateMobileItem(id) {
			this.loadingMobile = true;
			await this.validateItem(id).finally(() => {
				this.loadingMobile = false;
			});
		},
		fillTSO() {
			// scan Timesheets and fill tso structure
			var depNam = 'ft';
			var userTmp = {
				fullName: '',
				functionName: '',
				numTsForUser: 0,
				ts: []
			};
			this.MyTSObj = [];
			if (this.Timesheets.length > 0) {
				for (var i = 0; i < this.Timesheets.length; i++) {
					if (depNam == 'ft') {
						depNam = this.Timesheets[i].departmentName;
						// create Object
						this.MyTSObj = [];
						this.MyObj.depName = this.Timesheets[i].departmentName;
						this.MyObj.numTsForDep = 0;
						this.MyObj.users = [];
						userTmp.fullName = this.Timesheets[i].fullName;
						userTmp.functionName = this.Timesheets[i].functionName;
					} else if (this.Timesheets[i].departmentName != this.MyObj.depName) {
						// department change and user change, store myobj in array and reset my Object
						// push first current user to array
						var objTmp = Object.assign({}, userTmp);
						this.MyObj.users.push(objTmp);
						// reset userTmp
						userTmp.fullName = this.Timesheets[i].fullName;
						userTmp.functionName = this.Timesheets[i].functionName;
						userTmp.numTsForUser = 0;
						userTmp.ts = [];
						var objTmp2 = Object.assign({}, this.MyObj);
						this.MyTSObj.push(objTmp2);
						this.MyObj.depName = this.Timesheets[i].departmentName;
						this.MyObj.users = [];
						this.MyObj.numTsForDep = 0;
					} else if (this.Timesheets[i].fullName != userTmp.fullName) {
						// add this timesheet to user
						// push first current user to array
						var objTmp3 = Object.assign({}, userTmp);
						this.MyObj.users.push(objTmp3);
						// reset userTmp
						userTmp.fullName = this.Timesheets[i].fullName;
						userTmp.functionName = this.Timesheets[i].functionName;
						userTmp.numTsForUser = 0;
						userTmp.ts = [];
					}
					// add this timesheet to current user
					this.MyObj.numTsForDep++;
					userTmp.numTsForUser++;
					var objTmp4 = Object.assign({}, this.Timesheets[i]);
					userTmp.ts.push(objTmp4);
				}
				// push last user to array
				var objTmp5 = Object.assign({}, userTmp);
				this.MyObj.users.push(objTmp5);
				// push last MyObj to MyTSObj
				var objTmp6 = Object.assign({}, this.MyObj);
				this.MyTSObj.push(objTmp6);
			}
			//console.log("MyTSObj:", this.MyTSObj);
		},
		selectCurTs() {
			// check if curTs is in array of existing timesheet.
			// if yes, select it, if not, initialise a new curTs with defaultvalues
			// look if chosen date is in timesheet, if yes get structure
			var foundCurTsInArray = false;
			//console.log("in selectCurTs");
			for (var i = 0; i < this.Timesheets.length; i++) {
				var temp = this.Timesheets[i].strDate;
				temp = temp.substring(0, 10);
				console.log('temp:', temp);
				if (temp == this.curDay.strDate) {
					// fill curDay with tsTays Data
					this.curTs = this.Timesheets[i];
					//    console.log("in selectTs found line:", this.curTs.strDate)
					foundCurTsInArray = true;
					break;
				}
			}
			if (foundCurTsInArray == false) {
				if (this.Timesheets.length > 0) {
					this.curTs = this.Timesheets[0];
				} else {
					console.log('in selectCurTs not found in array');
					this.curTs.strDate = '2019-01-01';
				}
			}
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {},
		updateTZdataEach(timeData) {
			// setting row color
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			//timeData._rowVariant = this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			timeData.statusClass = classStatus(timeData.validated);
			//hh:mm
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;
			if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) >= 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) >= 10
			) {
				timeData.hhmmOvtTot =
					parseInt(timeData.hoursOvertime, 10) +
					parseInt(timeData.hoursOvertime1, 10) +
					parseInt(timeData.hoursOvertime2, 10) +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10));
			} else if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) >= 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) < 10
			) {
				timeData.hhmmOvtTot =
					(parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10))
						.toString()
						.padStart(2, '0') +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10));
			} else if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) < 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) >= 10
			) {
				timeData.hhmmOvtTot =
					parseInt(timeData.hoursOvertime, 10) +
					parseInt(timeData.hoursOvertime1, 10) +
					parseInt(timeData.hoursOvertime2, 10) +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10))
						.toString()
						.padStart(2, '0');
			} else if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) < 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) < 10
			) {
				timeData.hhmmOvtTot =
					(parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10))
						.toString()
						.padStart(2, '0') +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10))
						.toString()
						.padStart(2, '0');
			}
			// building full name or using email if name is not given
			if (timeData.user.name.length == 0 && timeData.user.firstName.length == 0) {
				timeData.fullName = timeData.email;
			} else {
				timeData.fullName = timeData.user.name + ' ' + timeData.user.firstName;
			}
			timeData.functionName = timeData.functionName.toLowerCase();
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		async reloadData() {
			// var StartDate = this.curMonthStr + "-01T00:00:00+00:00";
			await this.$apollo
				.query({
					query: query_timesheets,
					variables: {},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					result.data.GetTimesheetsToValidate.forEach(this.updateTZdataEach);
					this.Timesheets = result.data.GetTimesheetsToValidate;
					//_.orderBy(Timesheets, ['strDate'], ['desc']);
					//console.log("reloaddata:", this.Timesheets);
					this.fillTSO();
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		tsDetailLink(id) {
			return `/validateTS/${id.toString()}`;
		},
		rowClicked(item) {
			// copy item content into curTs structure.
			store.setCurTimesheet(item);
			//console.log("item :", item);
			const tsLink = this.tsDetailLink(item.id);
			//  console.log("rowclicked starting:",tsLink);
			this.$router.push({
				path: tsLink
			});
		},
		classStatus(validated) {
			return classStatus(validated);
		}
	}
};
</script>
